[class*="shape--"] {
	display: block;
	width: 1.25em;
	height: 1.25em;
	box-shadow: -1px 4px 2px rgba(black, 0.3);
}

[class*="shape_2x"] {
	width: 2em;
	height: 2em;
}


.shape--circle {
	border-radius: 50%;
}

.shape--diamond {
	transform: rotate(45deg);
	box-shadow: 2px 5px 2px rgba(black, 0.3);
}
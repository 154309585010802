.fancy-list {
	list-style: none;

	li {
		position: relative;
		margin-bottom: 0.25em;
	}

	li::before {
		content: "";
		display: block;
		position: absolute;
		left: -0.875em;
		top: 0.4em;
		border-left: solid 6px $color__branding--primary;
		border-top: solid 5px transparent;
		border-bottom: solid 5px transparent;
	}
}

.menu {
	list-style: none;
	display: flex;
	flex-direction: column;
	@include mq(tablet-landscape, min) {
		flex-direction: row;
		li {
			&:not(:first-child) {
			margin: {
				left: 1em;
			}
				border-left: solid 1px $color__branding--primary;
			}
		}
	}
}

.menu--footer {
	@extend .menu;
	li:not(:first-child) {
		padding-left: 1em;
		border-left: solid 1px $color__gray;
	}
}
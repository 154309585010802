/*
* --------------------------------------
* General Backgrounds
* --------------------------------------
*/

body {
	background-color: $color__light;
}

.reverse {
	background-color: $color__dark;
	color: mix(white, $color__dark, 90%);
	a {
		color: $color__offwhite;
	}
}
.positive {
	background-color: $color__light;
	color: mix(black, $color__light, 90%);
}

.card {
	box-shadow: 0 3px 5px rgba(0,0,0,0.35);
	// padding: 1em 2em 2em;
	background: {
		color: $color__offwhite;
		image: url('../images/textures/lightpaperfibers/lightpaperfibers_@2X.png');
		size: 500px 300px;
		repeat: repeat;
	}
}

.site-header {
	background-color: $color__offwhite;
}

.site-main {
	color: $color__dark;
	background: {
		image: url("../images/photos/country-sunrise.jpg");
		repeat: no-repeat;
		size: contain;
		position: top center;
	}
	&::before {
		content: "";
		display: block;
		border-right: solid 98vw $color__offwhite;
		border-bottom: solid 60px transparent;
	}
	&::after {
		content: "";
		display: block;
		@include spread;
		background-color: $color__offwhite;
	}
}

.bg {
	&--blue {
		background-color: $color__blue;
	}
	&--yellow {
		background-color: $color__yellow;
	}
	&--orange {
		background-color: $color__orange;
	}
	&--green {
		background-color: $color__green;
	}
}


/*
* --------------------------------------
* Specific Item Backgrounds
* --------------------------------------
*/

.branches {
	.unit__head {
		width: 80%;
		margin: {
			left: auto;
			right: auto;
			bottom: 0.5em;
		}
		border-bottom: solid 0.25em gray;

		h4 {
			margin-bottom: 0.25em;
		}
	}
	.unit {
		&:nth-child(1) .unit__head {
			border-color: $color__branding--primary;
		}
		&:nth-child(2) .unit__head {
			border-color: $color__branding--rockriver;
		}
		&:nth-child(3) .unit__head {
			border-color: $color__branding--tsts;
		}
		&:nth-child(4) .unit__head {
			border-color: $color__branding--secondary;
		}
		&:nth-child(5) .unit__head {
			border-color: $color__branding--sivyersteel;
		}
	}
}
/*
* --------------------------------------
* General Layout
* --------------------------------------
*/

.flex {
	display: flex;
	flex-direction: column;
	@include mq(tablet-landscape) {
		flex-direction: row;
	}
}
.flex--column {
	@extend .flex;
	flex-direction: column;
}
.justify-center {
	justify-content: center;
	justify-items: center;
}
.justify-end {
	justify-content: flex-end;
	justify-items: end;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.align-center {
	align-items: center;
}
.align-end {
	align-items: flex-end;
}

.grid {
	display: grid;
}

.text-center {
	text-align: center;
}
.text-unit--center {
	margin-bottom: 2em;
}

.container {
	position: relative;
	z-index: 1;
	width: 80vw;
	margin: {
		left: auto;
		right: auto;
		bottom: 4em;
	}
}

.inset {
	padding: 2em 4em;
}
.inset--vertical {
	padding: {
		top: 2em;
		bottom: 4em;
	}
}

.margin-bottom {
	margin-bottom: 2em;
}

.card__header {
	text-align: center;
	// margin-bottom: 2em;
	padding: 1em 2em 1px;
	position: relative;
	font-size: 0.875rem;

	&::before,
	&::after {
		content: "";
		display: block;
		// width: calc(100% - 2em);
		height: 1px;
		// Full width breakout:
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}

	&::before {
		position: absolute;
		bottom: 0;
		background-color: white;
	}
	&::after {
		position: absolute;
		bottom: -2px;
		background-color: transparentize($color__black--trans, 0.6);
	}
}

.card__body {
	p {
		padding: 0 2em 1em;
	}
	ul {
		padding-left: 3em;
		padding-right: 3em;
		padding-bottom: 1em;
	}
	img {
		max-width: 100%;
	}
}

.title-image {
	margin: 0;
}

.rule--short {
	width: 20vw;
	margin: 10vh auto;
	border: {
		top: solid 0.125em lighten($color__gray, 10%);
		left: none;
		bottom: none;
		right: none;
	}
}

.rule--hidden {
	border-top: 0;
}

.rule--below {
	margin-top: 2vh;
}




/*
* --------------------------------------
* Specific Item Layouts
* --------------------------------------
*/

.site-header {
	padding-bottom: 1em;
	width: 100%;
}

.site-footer {
	padding-top: 4em;
	padding-bottom: 4em;
	margin-top: -4em;
}

.site-main {
	position: relative;
	z-index: 1;

	section {
		position: relative;
		z-index: 1;
	}
}


.content--default {
	max-width: 72ch;
	margin: {
		left: auto;
		right: auto;
		top: 2em;
	}

	h2 {
		margin: {
			bottom: 0;
			top: 1.5em;
		}
	}
}






.app-layout {
	display: flex;
	flex-flow: column nowrap;
	@supports (display: grid) {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 40px 1fr 50px;
	}
}

.feature-cards {
	margin-top: -2em;
	position: relative;
	z-index: 2;

	@include mq(tablet-landscape) {
		display: flex;
		margin-top: 0;

		.card {
			margin-right: 2em;
			max-width: 50%;
			flex: 1;

			&:nth-child(n+2) {
				position: relative;
				top: 4em;
			}

			&:nth-child(n+3) {
				position: relative;
				top: 8em;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.contact {
	text-align: center;
	font-size: 1.5em;
	@include mq(md) {
		margin-top: 2em;
		font-size: 2em;
	}
}

.unit__image img {
	// max-width: 280px;
	max-width: 160px;
	min-width: 150px;
	max-height: 100%;
	// @include mq(tablet-landscape) {
	// 	max-width: none;
		// width: 200px;
	// }
}

.branches {
	.unit {
		margin: {
			left: auto;
			right: auto;
		}
		max-width: 90vw;
		@include mq(md) {
			max-width: 50vw;
		}
		@include mq(lg) {
			max-width: 20%;
			margin-right: 1em;
		}
		&:nth-child(3) .unit__image img {
			min-width: 200px;
		}
	}
	.unit__head,
	.unit__image {
		text-align: center;
	}
	.unit__image {
		@include mq(tablet-landscape) {
			height: 6em;
		}
	}
	.unit__body {
		padding: {
			left: 4em;
			right: 4em;
		}
		@include mq(tablet-portrait) {
			padding: 0;
		}
	}
}

.unit__image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.col-top {
	@include mq(tablet-landscape, min) {
		transform: translateX(-30em) translateY(4em);
	}
	@include mq(tablet-landscape, max) {
		transform: translateY(-10em);
		max-width: 60%;
		margin: {
			left: auto;
			right: auto;
		}

	}
}
.col-top,
.col-bottom {
	img {
		@include mq(lg, max) {
			max-width: 100%;
		}

	}
}

.legend__logo,
.legend__head {
	@include flex--centered;
}
.legend__head {
	margin: {
		top: 1em;
	}
	padding-bottom: 1em;
	border-bottom: solid 1px $color__gray;
	@include mq(tablet-landscape, min) {
		height: 3em;
	}
}
.legend__logo {
	@include mq(tablet-portrait, min) {
		img {
			width: 150px;
		}
	}
	@include mq(tablet-landscape, min) {
		height: 6em;
	}
}



.map__legend {
	flex-wrap: wrap;
	.col {
		flex: 1;
		@include mq(tablet-landscape, min) {
			margin-right: 1em;
		}
	}

	> .col {
		@include mq(tablet-landscape, max) {
			margin-bottom: 6em;
		}
	}

	> .col:first-child {
		flex: 2;
		.legend__head div {
			// margin-left: 2em;
		}
		.legend__logo .col {
			flex: 0 0 45%;
			// width: 50%;
		}
		.legend__logo .col:first-child img {
			width: 200px;
		}
		.legend__logo .col:last-child img {
			width: 120px;
		}
	}
}
%button {
	padding: 0.5em 1em;
	box-shadow: 0 2px 7px -2px rgba(black, 0.5);
	transform: translateY(0);
	transition: all 0.25s;
	display: inline-block;

	&:hover {
		box-shadow: 0 4px 10px 0px rgba(black, 0.3);
		transform: translateY(-0.125em);
	}

	&::before,
	&::after {
		content: none;
	}
}

.button {
	@extend %button;
	color: white;
	background-color: $color__branding--secondary;
	&:hover {
		color: white;
	}
}

.button--cta {
	@extend %button;
	color: white;
	background-color: $color__branding--raptor;
	&:hover {
		color: white;
	}
}

.image-link {
	&::before,
	&::after {
		content: none;
	}
}
body {
	font-family: $typeface__body;
	line-height: 1.4;
}

p, ul, ol, dl {
	margin-bottom: 0.5em;
}
ul, ol, dl {
	padding-left: 1em;
}
h1, h2, h3 {
	font-family: $typeface__heading;
}
h2 {
	font-size: 2rem;
	color: darken($color__branding--primary, 10%);
}
h3 {
	color: $color__branding--primary;
	text-transform: uppercase;
	font-weight: 100;
	font-size: 1.5rem;
}

a {
	color: $color__branding--primary;
	text-decoration: none;
	position: relative;
	&::before,
	&::after {
		content: "";
		position: absolute;
		display: inline-block;
		bottom: -1px;
		left: 0;
		height: 1px;
		transition: width 0.25s cubic-bezier(0,0.2,0.2,1);
	}
	&::before {
		width: 102%;
		background-color: $color__branding--secondary;
	}
	&::after {
		width: 0;
		background-color: $color__branding--primary;
	}

	&:hover {
		color: lighten($color__branding--primary, 10%);
		&::after {
			width: 102%;
		}
	}
}

::selection,
::-moz-selection {
	color: white;
	background-color: $color__branding--primary;
}
@keyframes fade-up {
	from {
		transform: translateY(2vh);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes background-zoom {
	from {
		background-size: 110%;
	}
	to {
		background-size: 100%;
	}
}

.site-logo {
	opacity: 0;
	animation: fade-in 0.5s 0.25s $animate-once;
}

.feature-cards {
	.card:nth-child(1) {
		opacity: 0;
		animation: fade-up 1s 2s $animate-once;
	}
	.card:nth-child(2){
		opacity: 0;
		animation: fade-up 1s 3s $animate-once;
	}
	.card:nth-child(3){
		opacity: 0;
		animation: fade-up 1s 4s $animate-once;
	}
}

.contact {
	opacity: 0;
	animation: fade-in 1s 5s $animate-once;
}

.site-main::after {
	animation: fade-out 1s 0.25s $animate-once;
}

//---------------------------------
// Layout
//---------------------------------

@function get-bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @return $breakpoint;
  }
}

  // Usage: mq(medium, min)
@mixin mq($point, $type: "min") {
  $breakpoint: null;
  @if type-of($point) == "number" {
    @if unitless($point) or unit($point) == "em" or unit($point) == "rem" or unit($point) == "%" {
      @warn "Please use pixel values.";
    } @else {
      $breakpoint: $point;
    }
  } @else if map-has-key($breakpoints, $point) {
    $breakpoint: map-get($breakpoints, $point);
  } @else {
    @warn "Couldn't find a breakpoint named `#{$point}`.";
  }

  @if $breakpoint != null {
    @if $type == "max" {
      @media screen and (max-width: $breakpoint - 1px) {
        @content;
      }
    } @else {
      @media screen and (min-width: $breakpoint) {
        @content;
      }
    }
  }
}

// Full width item
@mixin spread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin flex--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
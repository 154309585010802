// Colors:
$color__green: #6BB345;
$color__blue: #0B79BF;
$color__black--trans: rgba(black, 0.8);
$color__offwhite: #f9fafa;
$color__gray: #B4B4B4;
$color__orange: #fd4f00;
$color__yellow: #e29c28;

$color__dark: #021723;
$color__light: $color__offwhite;

$color__branding--primary: $color__blue;
$color__branding--secondary: $color__green;
$color__branding--raptor: $color__orange;
$color__branding--rockriver: #8a5959;
$color__branding--sivyersteel: #841e00;
$color__branding--tsts: #2caae2;


// Typefaces
$typeface__body: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$typeface__heading: "Josefin Sans", Tahoma, sans-serif;


// Breakpoints
$breakpoints: (
  phone-portrait:   360px,
  phone-landscape:  480px,
  tablet-portrait:  600px,
  tablet-landscape: 900px,
  sm: 							480px,
  md: 						 1024px,
  lg: 						 1200px,
  xl: 						 1360px
);


// Timings
$animate-once: cubic-bezier(0,0.7,0.7,1) forwards;